<template lang="html">
  <v-container>
    <v-layout justify-center wrap>
      <v-flex
        :class="{
          'mt-5': $vuetify.breakpoint.mdAndUp,
          'mt-2': $vuetify.breakpoint.smAndDown,
        }"
        mb-3
        xs12
        text-xs-center
      >
        <v-icon
          :class="{
            heading: $vuetify.breakpoint.mdAndUp,
            smallHeading: $vuetify.breakpoint.smAndDown,
          }"
        >
          question_answer
        </v-icon>
        <span
          :class="{
            heading: $vuetify.breakpoint.mdAndUp,
            smallHeading: $vuetify.breakpoint.smAndDown,
          }"
          >FAQ
        </span>
        <span class="heading ml-0 hidden-sm-and-down">
          : Frequently asked questions
        </span>
      </v-flex>
      <v-flex xs12 md8>
        <v-expansion-panel v-model="openState" expand focusable class="mb-4">
          <v-expansion-panel-content v-for="(item, i) in data" :key="i">
            <template v-slot:header>
              <div :style="faqQuestionStyles">
                {{ item.question }}
              </div>
            </template>
            <v-card :style="faqAnswerStyles">
              <v-icon class="mr-2">double_arrow</v-icon>
              <span v-html="item.answer"></span>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          question: 'What are these graphics?',
          answer:
            'These ‘warming stripe’ graphics are visual representations of the change in temperature as measured in each country, region or city over the past 100+ years. Each stripe or bar represents the temperature in that country, region or city averaged over a year. The stripes typically start around the year 1900 and finish in 2024, but for many countries, regions and cities the stripes start in the 19th century or sometimes even the 18th century. ',
        },
        {
          question: 'What do the graphics show?',
          answer:
            'For virtually every country, region or city, the stripes turn from mainly blue to mainly red in more recent years, illustrating the rise in average temperatures in that location.',
        },
        {
          question: 'Why are there no numbers on the graphics?',
          answer:
            'These graphics are specifically designed to be as simple as possible, and to start conversations about our warming world and the risks of climate change. There are numerous other sources of information which provide more specific details about how temperatures have changed, so these graphics fill a gap and enable communication with minimal scientific knowledge required to understand their meaning. There are four different versions of the graphics available for each location with different details included, allowing anyone to pick which version fits their audience best.',
        },
        {
          question: 'What can I do with these graphics?',
          answer:
            "The graphics have a <a target='_blank' href=' https://creativecommons.org/licenses/by/4.0/'>CC-BY</a>4.0 license, so can be used for any purpose as long as credit is given to Professor Ed Hawkins (University of Reading) and a link is provided to this website.",
        },
        {
          question: 'How have these graphics been made?',
          answer:
            "For most countries, the data comes from the <a target='_blank' href='http://www.berkeleyearth.org'>Berkeley Earth temperature dataset</a> to the end of 2023, extended with <a target='_blank' href='https://cds.climate.copernicus.eu/cdsapp#!/dataset/reanalysis-era5-land-monthly-means?tab=overview'>ERA5-Land</a> for 2024. For some countries (USA, UK, Switzerland, and Germany) the data instead comes from the relevant national meteorological agency. For each country and city the average temperature in 1961-2010 is set as the boundary between blue and red colours, and the colour scale varies from +/- 3.0 standard deviations of the annual average temperatures between 1901-2000. For the global average only, the UK Met Office <a target='_blank' href='https://www.metoffice.gov.uk/hadobs/hadcrut5/'>HadCRUT5.0 dataset</a> is used and the colour scale goes from -0.9°C to +0.9°C. The stripes are typically shown starting from around 1900 to 2024 but this can be longer or slightly shorter depending on the location and whether the historical data is available & considered robust. A small number of graphics have a few grey stripes which represent missing years of data (Hong Kong is one example with missing data during World War 2). We include these graphics to give a more complete set of countries and cities.",
        },
        {
          question: 'What country boundaries are you using?',
          answer:
            'We are using present day country boundaries for the whole time period shown.',
        },
      ],
      openState: [false],
    }
  },
  computed: {
    faqQuestionStyles() {
      return `font-size: ${this.$vuetify.breakpoint.mdAndUp ? 20 : 14}px;
        padding: ${this.$vuetify.breakpoint.mdAndUp ? 8 : 0}px;
        font-weight: 500;`
    },
    faqAnswerStyles() {
      return `padding: ${this.$vuetify.breakpoint.mdAndUp ? 25 : 16}px;
        font-size: ${this.$vuetify.breakpoint.mdAndUp ? 16 : 12}px;
        background-color: #292929;`
    },
  },
}
</script>
<style lang="css" scoped>
>>> .v-expansion-panel__header {
  background-color: #202020;
}
/* FIXME: Consider this instead of using computed styles */
.heading {
  font-size: 24px;
  font-weight: 500;
  margin-left: 10px;
}
.smallHeading {
  font-size: 20px;
  font-weight: 500;
  margin-left: 5px;
}
</style>
