<template lang="html">
  <div class="map-container">
    <l-map
      :zoom="zoom"
      :center="center"
      :options="{ zoomSnap: 0.25, zoomDelta: 0.25 }"
      @zoom="$store.zoom = $event.target.getZoom()"
      @moveend="$store.center = $event.target.getCenter()"
      class="map"
      ref="map"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker
        :lat-lng="marker.location"
        :icon="markerIcon"
        v-for="(marker, i) in markers"
        :key="i"
        @popupopen="poppedUp"
      >
        <l-popup
          class="popup"
          :options="{ autoClose: true, closeOnClick: true }"
          :style="getBgImage(marker.details?.url.display_link)"
          @click="goToStripe(marker.path)"
        >
          <button @click="goToStripe(marker.path)">
            {{ marker.name }}
          </button>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import iconSvg from '@/assets/marker-icon.svg'
import { icon } from 'leaflet'

export default {
  data() {
    return {
      // url: 'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png',
      // attribution:
      //   '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: this.$store.mapLocations,
    }
  },

  computed: {
    markerIcon() {
      let size = Math.floor(4 + 2 ** (this.zoom / 2))
      if (this.zoom >= 10) {
        size = 50
      }
      return icon({
        iconUrl: iconSvg,
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
      })
    },
    zoom() {
      return this.$store.zoom
    },
    center() {
      return this.$store.center
    },
    // zoom: {
    //   get() {
    //     return this.$store.zoom
    //   },
    //   set(value) {
    //     console.log('setting zoom')
    //     this.$store.zoom = value
    //   },
    // },
    // center: {
    //   get() {
    //     return this.$store.center
    //   },
    //   set(value) {
    //     this.$store.center = value
    //   },
    // },
  },
  methods: {
    goToStripe(path) {
      const pathComponents = path.split('/')
      const region = pathComponents.length > 1 ? pathComponents[1] : ''
      const country = pathComponents.length > 2 ? pathComponents[2] : ''
      const location = pathComponents.length > 3 ? pathComponents[3] : ''

      const params = {
        stripeType: this.$store.selectedStripeType || 's',
      }
      if (region) {
        params.region = region
        this.$store.selectedRegion = region
      }
      if (country) {
        params.country = country
        this.$store.selectedCountry = country
      }
      if (location) {
        params.location = location
        this.$store.selectedLocation = location
      }

      this.$router.push({
        name: 'stripes',
        params,
      })
    },
    poppedUp(event, a) {
      this.$store.openMarker = event.target
    },
    getBgImage(url) {
      return {
        backgroundImage: `url("${url}")`,
      }
    },
  },
  components: { LMap, LTileLayer, LMarker, LPopup },
  mounted() {
    if (this.$store.openMarker) {
      this.$store.openMarker.openPopup()
    }
    if (this.$store.firstMapRun) {
      this.$refs.map.mapObject.fitBounds([
        [-40, -180],
        [78, 180],
      ])
      this.$store.firstMapRun = false
    }
  },
}
</script>

<style lang="css">
.map-container {
  width: 100%;
  height: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  /* border: 1px solid red; */
}

.map {
  flex: 0 0 100%;
  z-index: 1;
  border: 0.5rem solid rgba(0, 0, 0, 0.825);
}

.panel {
  flex-basis: 0%;
  /* background-color: #202020; */
  height: 50%;
  min-height: 100px;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-close-button span {
  color: red;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black;
}

.popup {
  display: flex;
  flex-direction: column;
  background-size: cover;
  border-radius: 12px;
  cursor: pointer;
}

.popup button {
  padding: 1rem 3rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: black;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}
</style>
